import { React, useState } from "react";
import { Casino_list } from '../list/casino_list.jsx';
import '../css/Casinos.css';
import VerifiedIcon from '@mui/icons-material/Verified';

const CasinoBox = () => {
    const [Casinos] = useState(Casino_list);
    // #C85858
    const hexcolor = "#2596be";
    const curcolor = "#00FF00";
    const crcolor = "#FF0000";
    const escolor = "#ADD8E6";

    return (
        <div data-aos='fade-up'>
            <div>
                <p className="Other_offer_text">BONUSES 2025</p>
                <div className='first_box'>
                <p className='MGA_info_text_first' title="Malta gambling license"> <VerifiedIcon style={{ color: hexcolor }} /> = MGA License</p>
                <p className='MGA_info_text_first' title="Curacao gambling license"><VerifiedIcon style={{ color: curcolor }} /> = CUR License</p>
                </div>
                <div className='second_box'>
                <p className='MGA_info_text_second' title="Costa Rica gambling license"><VerifiedIcon style={{ color: crcolor }} /> = CR License</p>
                <p className='MGA_info_text_second' title="Estonia gambling license"><VerifiedIcon style={{ color: escolor }} /> = ES License</p>
                </div>
            </div>
            <div className='outer_div'>
                {Casinos.map((item) => (
                    <div className="item_box" key={item.id}>
                        {item.NEW ? (
                        <div className='new_casino'>
                        <p>NEW</p>
                        </div>
                        ): (
                            <></>
                        )}
                        {item.MGA ? (
                            <div className='MGA' title="This casino has a MGA license">
                                {/* <span className='MGA_text'>Mga</span> */}
                                <VerifiedIcon style={{ color: hexcolor }} />
                            </div>
                        ) : (
                            <></>
                        )}
                        {item.CUR ? (
                            <div className='CUR' title="This casino has a CUR license">
                                <VerifiedIcon style={{ color: curcolor }} />
                            </div>
                        ) : (
                            <></>
                        )}
                        {item.CR ? (
                            <div className='CR' title="This casino has a CR license">
                                <VerifiedIcon style={{ color: crcolor }} />
                            </div>
                        ) : (
                            <></>
                        )}
                        {item.ES ? (
                            <div className='ES' title="This casino has a ES license">
                                <VerifiedIcon style={{ color: escolor }} />
                            </div>
                        ) : (
                            <></>
                        )}
                        <img src={item.img} alt={item.titel} width={item.width}  height={item.height} className="swedish-offer-image"/>
                        <p className="offer_text">{item.offer1}</p>
                        <p className="offer_text">{item.offer2}</p>
                        <p className="offer_text">{item.offer3}</p>
                        <p className="offer_text">{item.offer4}</p>
                        <p className="offer_text">{item.offer5}</p>
                        <p className="offer_text">{item.offer6}</p>

                        <div className="claim_box">
                            <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='claim_button_other'
                            >
                                <p className='button_text_other'>Claim</p>
                            </a>
                        </div>
                    </div>
                ))
                }

            </div >
        </div>
    );
}
export default CasinoBox;