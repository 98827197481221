import { React, useState } from "react";
import { SWE_Casino_list } from '../list/swe_casino_list.jsx';
import '../css/Casinos.css';
import VerifiedIcon from '@mui/icons-material/Verified';

const SwedishCasinoBox = () => {
    const [SwedishCasinos] = useState(SWE_Casino_list);
    const sweColor = "#FFFF00";

    return (
        <div className="swedish-container">
            <p className="swedish-title">BEST SWEDISH BONUSES 2025</p>
            <div className='swedish-license-box'>
                <p className='swedish-license-text' title="Swedish gambling license">
                    <VerifiedIcon style={{ color: sweColor }} /> = Swedish License
                </p>
            </div>
            
            <div className='swedish-offers-container'>
                {SwedishCasinos.map((item) => (
                    <div className="swedish-offer-box" key={item.id}>
                        {item.NEW && (
                            <div className='swedish-new-badge'>
                                <p>NEW</p>
                            </div>
                        )}
                        {item.SWE && (
                            <div className='swedish-license-badge' title="This casino has a SWE license">
                                <VerifiedIcon style={{ color: sweColor }} />
                            </div>
                        )}
                        
                        <img 
                            src={item.img} 
                            alt={item.titel} 
                            width={item.width} 
                            height={item.height}
                            className="swedish-offer-image"
                        />
                        <p className="swedish-offer-text">{item.offer1}</p>
                        <p className="swedish-offer-text">{item.offer2}</p>
                        <p className="swedish-offer-text">{item.offer3}</p>
                        <p className="swedish-offer-text">{item.offer4}</p>
                        <p className="swedish-offer-text">{item.offer5}</p>
                        <p className="swedish-offer-text">{item.offer6}</p>

                        <div className="swedish-claim-box">
                            <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='swedish-claim-button'
                            >
                                <p className='swedish-button-text'>Claim</p>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SwedishCasinoBox;