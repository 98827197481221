import { React, useState } from "react";
import { BestOffer } from '../list/best_offer.jsx';
import VerifiedIcon from '@mui/icons-material/Verified';
import '../css/Casinos.css'


const BestOfferBox = () => {
    const [Offer] = useState(BestOffer);
    // #C85858
    const hexcolor = "#2596be";
    const curcolor = "#00FF00";
    const crcolor = "#FF0000";

    return (
        <div className='best_offer_box' data-aos='fade-up'>
            {/* <img src={casino_chips} alt="background" height='200px' width='200px' /> */}
            <div>
                <p className="Best_offer_text">BEST OFFER</p>
            </div>
            <div className='img_div'>
            {Offer.NEW ? (
                        <div className='new_casino_best'>
                        <p>NEW</p>
                        </div>
                        ): (
                            <></>
                        )}
            {Offer.MGA ? (
                            <div className='MGA' title="This casino has a MGA license">
                                <VerifiedIcon style={{ color: hexcolor }} />
                            </div>
                        ) : (
                            <></>
                        )}
                        {Offer.CUR ? (
                            <div className='CUR' title="This casino has a CUR license">
                                <VerifiedIcon style={{ color: curcolor }} />
                            </div>
                        ) : (
                            <></>
                        )}
                        {Offer.CR ? (
                            <div className='CR' title="This casino has a CR license">
                                {/* <span className='MGA_text'>Mga</span> */}
                                <VerifiedIcon style={{ color: crcolor }} />
                            </div>
                        ) : (
                            <></>
                        )}
                <img src={Offer.img} alt={Offer.titel} width={Offer.width}  height={Offer.height} className='Best_offer_img' />
                <p className='offer_text_best best_offer_top_text'>{Offer.offer1}</p>
                <p className='offer_text_best'>{Offer.offer2}</p>
                <p className='offer_text_best'>{Offer.offer3}</p>
                <p className='offer_text_best'>{Offer.offer4}</p>
                <p className='offer_text_best'>{Offer.offer5}</p>
                <div className="claim_box_best">
                    <a
                        href={Offer.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className='claim_button_bestoffer'
                    >
                        <p className='button_text'>Claim</p>
                    </a>
                </div>
            </div>


        </div>
    );
}
export default BestOfferBox;
