import ninecasino from '../img/ninecasino.png'



export const BestOffer =
{
        "id": 1,
        "titel": 'Nine Casino',
        "img": ninecasino,
        "link": 'https://track.chillipartners.com/visit/?bta=37612&nci=5558',
        "offer1": '',
        "offer2": '1st: 100% Bonus up to 100€',
        "offer3": '2nd: 55% Bonus up to 150€',
        "offer4": '3rd: 100% Bonus up to 150€',
        "offer5": 'Wager 35x',
        "MGA": false,
        "CUR": true,
        "CR": false,
        "ES": false,
        "width": 200,
        "height":150,
}

