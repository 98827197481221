import betinia from '../img/betinia.png';
import yoyocasino from '../img/yoyocasino.png';
import Campobet from '../img/campobet.png';


export const SWE_Casino_list = [
  
    {
        "id": 0,
        "titel": 'Yoyo',
        "img": yoyocasino,
        "link": 'https://yoyocasinose.wermifal.com/?mid=14281_1538645',
        "offer1": '',
        "offer2": '100% Bonus up to 2500SEK',
        "offer3": 'Wager 35x',
        "offer4": '',
        "offer5": '',
        "SWE": true,
        "width": 400,
        "height":150,
        'NEW': true,
    },
    {
        "id": 1,
        "titel": 'Campobet',
        "img": Campobet,
        "link": 'https://campobetse.wermifal.com/?mid=14281_1538646',
        "offer1": '',
        "offer2": '100% Bonus up to 2500SEK',
        "offer3": 'Wager 35x',
        "offer4": '',
        "offer5": '',
        "SWE": true,
        "width": 400,
        "height":150,
        'NEW': true,
    },
    {
        "id": 2,
        "titel": 'Betinia',
        "img": betinia,
        "link": 'https://betiniase.wermifal.com/?mid=14281_1494219',
        "offer1": '',
        "offer2": '100% Bonus up to 2500SEK',
        "offer3": 'Wager 35x',
        "offer4": '',
        "offer5": '',
        "SWE": true,
        "width": 400,
        "height":150,
        'NEW': false,
    }
]
